import * as Sentry from '@sentry/react';

if (process.env.SENTRY_API_PATH?.length) {
  Sentry.init({
    dsn: process.env.SENTRY_API_PATH,
    release: process.env.RELEASE,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
